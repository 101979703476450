<template>
  <div class="scoreDetails">
    <div v-if="type===0">
      <div style="font-weight: bold">竹香学校教师年度考核业务素养评分细则</div>
      <hr>
      <div>考核对象：{{$route.query.userName}}
		<span style="margin-left: 80px">评分时间：{{$route.query.yearId}}年度</span></div>
      <el-table :data="scoreList"
        border>
        <el-table-column label="领域"
          width="40px"
          prop="ly"></el-table-column>
        <el-table-column label="项目"
          width="120px"
          prop="xm"></el-table-column>
        <el-table-column label="评分细则"
          prop="xz"></el-table-column>
        <el-table-column label="评分说明"
          prop="sm"></el-table-column>
        <el-table-column label="分值"
          width="50px"
          align="center"
          prop="maxscore"></el-table-column>
        <el-table-column label="自评"
          width="50px"
          align="center"
          prop="zscore"></el-table-column>
        <el-table-column label="得分"
          width="50px"
          align="center"
          prop="score"></el-table-column>
        <el-table-column label="签名"
          width="50px"
          align="center"
          prop="opername"></el-table-column>
        <el-table-column label="部门"
          width="120px"
          align="center"
          prop="groupname"></el-table-column>
      </el-table>
    </div>
    <el-table :data="scoreList"
      v-else>
      <el-table-column label="被评价人"
        align="center"
        prop="assessed_name"></el-table-column>
      <el-table-column label="班级"
        align="center"
        prop="cname"></el-table-column>
      <el-table-column label="评价人"
        align="center"
        prop="evaluation_name"></el-table-column>
      <el-table-column label="科组"
        align="center"
        prop="bookname"
        v-if="type===1"></el-table-column>
      <el-table-column label="学科"
        align="center"
        prop="course"
        v-if="type===1"></el-table-column>
      <el-table-column label="分值"
        align="center"
        prop="score"></el-table-column>
      <el-table-column label="状态"
        align="center">
        <template slot-scope="scope">
          <el-tag :type="tagColor(scope.row.status)">{{scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否及格"
        align="center"
        prop="passStr"></el-table-column>
      <el-table-column label="完成时间"
        align="center"
        prop="opertime"></el-table-column>
      <el-table-column label="详情"
        align="center">
        <template slot-scope="scope">
          <el-button type="primary"
            @click="viewQuestion(scope.row)">查看问卷</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="questionVisible"
      width="600px"
      title="问卷详情">
      <div class="question-box">
        <div v-for="template in questionList"
          :style="oneTicketVetoOption(template)"
          :key="template.id">
          <div class="padding-box">
            {{template.sort_index}}、{{template.title}}
            {{template.type === 1 ? '(一票否决项)' : ''}}
          </div>
          <el-radio-group v-model="template.option_id"
            class="padding-group"
            disabled>
            <el-radio v-for="optic in template.optionList"
              :key="optic.id"
              :label="optic.id">{{optic.content}}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "scoreDetails",
  data() {
    return {
      scoreList: [],
      type: Number(this.$route.query.type),
      questionVisible: false,
      questionList: [],
    };
  },
  created() {
    this.loadEvaluationList();
  },
  watch: {
    questionList: {
      handler(val) {},
      deep: true,
    },
  },
  methods: {
    loadEvaluationList() {
      const that = this;
      let data = {
        nd: this.$route.query.yearId,
        managerid: Number(this.$route.query.managerId),
      };
      let apiUrl = "/base/queryYwQuestionDetailByAssessed.do";
      if (this.type === 1) {
        apiUrl = "/base/queryTeaQuestionDetailByAssessed.do";
      }
      if (this.type === 2) {
        apiUrl = "/base/queryStuQuestionDetailByAssessed.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          if (this.type === 0) {
            //记录领域类型
            let columnTitles = [];
            res.data.forEach((item) => {
              //记录同一领域的第一条数据，并赋值rowspan
              if (columnTitles.indexOf(item.ly) === -1) {
                item.rowspan = 0;
                columnTitles.push(item.ly);
              }
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].ly === item.ly) {
                  item.rowspan += 1;
                }
              }
            });
          }
          that.scoreList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewQuestion(currentScore) {
      const that = this;
      let apiUrl = "/base/PQueryQuestionDetail.do"; //教师问卷
      let data = { ndid: currentScore.ndid };
      if (this.type === 1) {
        data.qtid = currentScore.id;
      }
      if (this.type === 2) {
        //家长问卷
        apiUrl = "/parent/queryStuQuestionDetailByStu.do";
        data.evaluation_mid = currentScore.evaluation_mid;
        data.stuNo = currentScore.stuno;
        data.assessed_mid = currentScore.assessed_mid;
        data.classid = currentScore.classid;
      }
      this.$post(apiUrl, data)
        .then((res) => {
          that.questionList = res.data;
          that.questionVisible = true;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    tagColor(status) {
      switch (status) {
        case 0:
          return "success";
        case 1:
          return "warning";
        case 2:
          return "info";
        case 3:
          return "";
      }
    },
    oneTicketVetoOption(option) {
      if (option.type === 1) {
        return "color: rgb(211, 90, 90)";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.padding-box {
  padding: 10px;
}
.question-box {
  height: 500px;
  overflow-x: auto;
}
</style>
